import {Action, Selector, State, StateContext} from '@ngxs/store';
import {CmvApiService} from '@matchsource/api/cmv';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {GetCmv} from './cmv.action';
import {of} from 'rxjs';
import {Nomenclature} from '@matchsource/models/nomenclature';

export type CmvStateModel = EntitiesStateModel<Nomenclature>;

@State<CmvStateModel>({
  name: 'cmv',
  defaults: defaultEntitiesState<Nomenclature>(),
})
@Injectable()
export class CmvState {
  @Selector([CmvState])
  static map(state: CmvStateModel) {
    return state.entities;
  }

  @Selector([CmvState])
  static isLoaded(state: CmvStateModel) {
    return state.loaded;
  }

  constructor(private readonly cmvApi: CmvApiService) {}

  @Action(GetCmv)
  getAll(ctx: StateContext<CmvStateModel>) {
    const state = ctx.getState();
    if (state.loaded || state.loading) {
      return;
    }

    ctx.setState(loadingEntities(true));

    return this.cmvApi.getAll().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(entities =>
        ctx.setState(
          compose(addOrReplaceEntities<Nomenclature>('code', entities), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
