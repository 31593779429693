import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngxs/store';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {EthnicityState, EthnicityStateModel} from './ethnicity.state';
import {GetEthnicity} from './ethnicity.actions';
import {Nomenclature} from '@matchsource/models/nomenclature';

const byCode = (state: EthnicityStateModel) => (code: string) => state.entities[code];

@Injectable({
  providedIn: 'root',
})
export class EthnicityService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public readonly data$: Observable<EthnicityStateModel>;
  readonly ethnicities$: Observable<Nomenclature[]>;

  constructor(private readonly store: Store) {
    this.data$ = this.store
      .select((state: any) => state.ethnicity as EthnicityStateModel)
      .pipe(
        distinctUntilChanged(),
        tap(ethnicityState => {
          if (!ethnicityState.loaded && !ethnicityState.loading) {
            this.store.dispatch(new GetEthnicity());
          }
        }),
        filter(() => this.store.selectSnapshot(EthnicityState.isLoaded)),
        takeUntil(this.destroy$),
        shareReplay({refCount: true, bufferSize: 1})
      );

    this.ethnicities$ = this.data$.pipe(
      map((state: EthnicityStateModel) => Object.keys(state.entities).map(key => state.entities[key]))
    );
  }

  getByCode(code: string): Observable<Nomenclature> {
    return combineLatest([of(code), this.data$]).pipe(map(([ethnicityId, data]) => byCode(data)(ethnicityId)));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
