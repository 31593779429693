import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngxs/store';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {distinctUntilChanged, filter, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {GetCmv} from './cmv.action';
import {CmvState, CmvStateModel} from './cmv.state';
import {Nomenclature} from '@matchsource/models/nomenclature';

const byId = (state: CmvStateModel) => (id: MsApp.Guid) => state.entities[id];

@Injectable({
  providedIn: 'root',
})
export class CmvService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public readonly data$: Observable<CmvStateModel>;

  public readonly loading$: Observable<boolean>;

  readonly cmvStatuses$: Observable<Nomenclature[]>;

  constructor(private readonly store: Store) {
    this.data$ = this.store
      .select((state: any) => state.cmv as CmvStateModel)
      .pipe(
        distinctUntilChanged(),
        tap(cmvState => {
          if (!cmvState.loaded && !cmvState.loading) {
            this.store.dispatch(new GetCmv());
          }
        }),
        filter(() => this.store.selectSnapshot(CmvState.isLoaded)),
        takeUntil(this.destroy$),
        shareReplay({refCount: true, bufferSize: 1})
      );

    this.loading$ = this.data$.pipe(map(state => state.loading));

    this.cmvStatuses$ = this.data$.pipe(
      map((state: CmvStateModel) => Object.keys(state.entities).map(key => state.entities[key]))
    );
  }

  getById(id: MsApp.Guid): Observable<Nomenclature> {
    return combineLatest([of(id), this.data$]).pipe(map(([cmvId, data]) => byId(data)(cmvId)));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
