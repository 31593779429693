import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngxs/store';
import {Observable, of, Subject} from 'rxjs';
import {filter, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {DEFAULT_RACE, RaceCode, RaceModel} from '@matchsource/models/nomenclature';
import {RacesState} from './races.state';
import {GetRaces} from './races.action';
import {uniq} from '@matchsource/utils';

@Injectable({
  providedIn: 'root',
})
export class RacesService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  readonly races$: Observable<MsApp.Dictionary<RaceModel>>;

  readonly raceCodes$: Observable<RaceCode[]>;

  constructor(private readonly store: Store) {
    this.races$ = this.store.select(RacesState.races).pipe(
      tap(() => {
        if (!this.store.selectSnapshot(RacesState.inProgressOrLoaded)) {
          this.store.dispatch(new GetRaces());
        }
      }),
      filter(() => this.store.selectSnapshot(RacesState.isLoaded)),
      takeUntil(this.destroy$),
      shareReplay({refCount: true, bufferSize: 1})
    );

    this.raceCodes$ = this.store.select(RacesState.raceCodes).pipe(
      tap(() => {
        if (!this.store.selectSnapshot(RacesState.inProgressOrLoaded)) {
          this.store.dispatch(new GetRaces());
        }
      }),
      takeUntil(this.destroy$),
      shareReplay({refCount: true, bufferSize: 1})
    );
  }

  getBroadRaces(raceCodes: string[]): Observable<string[]> {
    if (!raceCodes || raceCodes.length <= 0) {
      return of([DEFAULT_RACE.broadRaceDescription]);
    }

    return this.races$.pipe(
      map(races => {
        if (!Object.values(races).length) {
          return [];
        }

        const broadRaceDescriptions = raceCodes.map(race => {
          return races[race] && races[race].broadRaceDescription;
        });

        return uniq(broadRaceDescriptions);
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
